<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col cols="10" sm="8" md="6" lg="4">
            <v-card elevation="2" class="my-6" v-if="errorInvalidInteraction">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>An unexpected error occurred</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>Use your back button to return to the previous site.</p>
                    <!-- TODO: if organization has enterprise branding, then mainWebsiteURL needs to be enterprise website URL, and "Learn more about ..." needs to have enterprise name instead of Unicorn Springs -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>

            <!-- The "not found" card usually is not displayed because the user is redirected to the catalog
            immediately when we don't get a result from the server; but if the redirect is taking time, the
            user would see this card. Instead of showing it like an error message, we show it as a next step
            to select a subscription from the catalog.
            UPDATE: instead of showing this we just redirect the user to that activity, there's
            no reason to make them click the link. -->
            <v-card elevation="2" class="my-6" v-if="errorProductNotFound">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Sign up</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>Continue to <router-link :to="{ name: 'brand-catalog-search', params: { brandprofile: $route.params.brandprofile }, query: { type: 'service' } }">select a subscription</router-link>.</p>
                </v-card-text>
            </v-card>
            <v-card elevation="2" class="my-6" v-if="errorProductNotPriced">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>An unexpected error occurred</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>This product isn't ready for sale because it doesn't have a published price.</p>
                    <p>Use your back button to return to the previous site.</p>
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- TODO: if organization has enterprise branding, then mainWebsiteURL needs to be enterprise website URL, and "Learn more about ..." needs to have enterprise name instead of Unicorn Springs -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isError && isAuthenticated && user">
                <CheckoutProfileAccountBar></CheckoutProfileAccountBar>
            </template> -->

            <template v-if="!isError && (!isAuthenticated || !user)">
            <v-card elevation="2" class="mt-6">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <!-- TODO: instead of product name in title bar here, we should put "Sign up" in the title bar and make the entire page have two parts, left side is the product information with the prodcut name, description, cover image, features, price, free trial option,  etc. (and if the product is in a product group, there can also be a section showing the other products in the group, especially if there are any upgrades avaailble, so user can switch to that before signing up) and the right side would have this sign up card with the sign in link below it ;  OR use the entire page background for describing the product and put this card in the foreground , and description etc. should flow around the card so user doesn't miss any information -->
                    <v-app-bar-title>Sign up</v-app-bar-title>
                </v-app-bar>
                <v-progress-linear :color="primaryColor" height="10" :value="progress" striped class="mb-6">
                </v-progress-linear>
                <template v-if="step === 'start' && !redirect && !isAuthenticated">
                <!-- <v-row no-gutters>
                    <v-col cols="6">
                        <v-card-text>{{ product.name }}</v-card-text>
                    </v-col>
                    <v-col cols="6"> -->
                        <v-card-text v-if="!isAuthenticated">
                            <!-- This activity is to sign up for a service, and services always require an authenticated user with a non-guest account -->
                            <p>Getting started is quick and easy. What is your email address?</p>
                            <v-form @submit.prevent="signupWithEmail" @keyup.enter.prevent="signupWithEmail">
                                <v-text-field v-model="email" dense label="Email" :color="primaryColor" outlined ref="emailInput" hint="This will be your username. You can change it later." :messages="emailInputError">
                                    <template #prepend-inner>
                                        <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                                    </template>
                                </v-text-field>
                                <v-row no-gutters justify="center">
                                    <v-btn :style="primaryButtonStyle" @click="signupWithEmail" class="mt-2">Continue</v-btn>
                                </v-row>
                            </v-form>
                            <!-- <p>Return to the last page and contact the emergency home for details.
                            <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                            <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                            <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                        </v-card-text>
                        <v-card-text v-if="isAuthenticated && !user">
                            <v-progress-circular indeterminate />
                        </v-card-text>
                    <!-- </v-col>
                </v-row> -->
                </template>
                <template v-if="['start', 'verify_email'].includes(step) && redirect">
                    <v-card-text>
                        <p><a :href="redirect">Continue to email verification</a></p>
                    </v-card-text>
                </template>
                <template v-if="step === 'verify_email' && !isViewReady">
                    <v-card-text>
                        <p>Please wait...</p>
                    </v-card-text>
                </template>
                <template v-if="step === 'verify_email' && isViewReady && registered">
                    <v-text-field v-model="email" dense solo flat :color="primaryColor" placeholder="Email" readonly>
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/> <!-- style="font-size: 20px;" -->
                        </template>
                    </v-text-field>
                    <v-card-text>
                        <!-- NOTE: since the user verified the email address, we can inform them of the status -->
                        <p>
                            This email address has already been registered.
                        </p>
                        <p><router-link :to="{ name: 'brand-login', params: { ...$route.params }, query: { email } }">Sign in to your account</router-link></p>
                        <p><a @click.prevent="switchAccount" href="#">Use a different email address</a></p>
                    </v-card-text>
                </template>
                <template v-if="step === 'verify_email' && isViewReady && !registered">
                    <v-card-text>
                        <p>Please wait...</p>
                    </v-card-text>
                </template>
                <template v-if="step === 'display_name'">
                    <v-card-text>
                        <p>What is your name?</p>
                        <p class="text-caption">We will use this name to address you on our website, in emails, account statements, and any other communications.</p>
                    </v-card-text>
                    <v-form @submit.prevent="signupWithDisplayName" onSubmit="return false;" @keyup.enter.native.prevent="signupWithDisplayName" class="mx-4 pb-6">
                        <v-text-field v-model="displayName" ref="displayNameInput" dense solo :color="primaryColor" hint="Your full name, first name, or a nickname. You can change it later." placeholder="Name" :error-messages="nameInputError">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'user']" fixed-width/>
                            </template>
                        </v-text-field>
                        <v-row no-gutters justify="center">
                            <v-btn @click="signupWithDisplayName" elevation="4" :style="primaryButtonStyle" class="mt-4">Continue</v-btn>
                        </v-row>
                    </v-form>
                </template>
                <!-- <template v-if="step === 'notice' && isViewReady">
                    <v-card-text>
                        <p>Almost there!</p>
                        <p>We want to take this opportunity to thank you for joining us. We are always interested in learning how to serve you even better. If you encounter any issues or want to share your thoughts, please use the question mark icon at the top of the page to get in touch with us.</p>
                        <p>The next steps are to set up your profile and account.</p>
                        <v-btn :style="primaryButtonStyle" @click="continueAfterSignup" class="mt-4">Continue</v-btn>
                    </v-card-text>
                </template> -->
            </v-card>
            <p class="mt-4 grey--text text--darken-2 text-center" style="font-size: 0.9em;">
                Already have an account? <a href="#" @click="login">Sign in</a>
            </p>
            </template>
            <!-- isError {{ isError }} isAuthenticated {{ isAuthenticated }} step {{ step }} isViewReady {{ isViewReady }} user {{ JSON.stringify(user) }} -->
            <template v-if="!isError && isAuthenticated && user">
                <!-- When an authenticated user arrives, we ask them to select the account they want to use for this transaction (because signup transaction is outside of account) and allow the user to create a new account -->
                <template v-if="step === 'start' && isViewReady">
                    <v-card elevation="2" class="mt-6">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Select account</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text class="text-start">
                            <template v-if="!Array.isArray(accountList)">
                                <v-progress-circular indeterminate></v-progress-circular>
                            </template>
                            <template v-if="Array.isArray(accountList) && accountList.length === 0">
                                <p class="mt-4">You don't have an account yet.</p>
                                <!-- TODO: button to create account would show the new account type and name prompt, then select it, then continue -->
                                <!-- Since user is authenticated we have their name... so create an individual account with the account name same as their profile name; they can always change it later -->
                            </template>
                            <template v-if="Array.isArray(accountList) && accountList.length > 0">
                                <!-- If a user has at least one account, show the list and also a button to switch accounts or create a new account -->
                                <!-- <p class="mt-4">Select an account:</p> -->
                                <CheckoutSelectAccount @selected="selectAccount"></CheckoutSelectAccount>
                            </template>
                        </v-card-text>
                    </v-card>
                </template>
                <!-- TODO: maybe enable this as an option in organization admin; for now we skip it because it's just an extra step that makes the customer think about one more thing before they buy, instead of just getting them through the checkout; since it can be changed later anyway, it's better to handle it later -->
                <!--
                <template v-if="step === 'create_account' && isViewReady">
                    <v-card elevation="2" class="mt-6">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Let's create your account</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text class="text-start">
                            <p class="mt-4">Will you be using this account alone or with others? (You can change this later)</p>
                        <v-form>
                            <v-radio-group v-model="newAccountType" column dense>
                                <v-radio label="Individual" :value="ACCOUNT_TYPE_INDIVIDUAL"></v-radio>
                                <v-radio label="Team" :value="ACCOUNT_TYPE_TEAM"></v-radio>
                                <v-radio label="Enterprise" :value="ACCOUNT_TYPE_ENTERPRISE"></v-radio>
                            </v-radio-group>
                            <p v-if="newAccountType === ACCOUNT_TYPE_INDIVIDUAL">
                                You will be the only user on this account. This option is suitable for hobbyists, professionals, and solopreneurs.
                            </p>
                            <p v-if="newAccountType === ACCOUNT_TYPE_TEAM">
                                You'll be the account owner (you can change this later). You will be able to restrict certain account actions so they require your permission. This option is suitable for small business and other situations where the account owner needs to invite at least one other person to be a DNS administrator.
                            </p>
                            <p v-if="newAccountType === ACCOUNT_TYPE_ENTERPRISE">
                                You'll be the first account administrator. You can then invite others to become authorized technical, billing, or customer support contacts. You will have the ability to manage permissions in detail.
                            </p>
                            <v-text-field v-model="newAccountName" dense :label="newAccountNameLabel" :color="primaryColor" outlined ref="newAccountNameInput" hint="This will be the account name. You can change it later." v-if="newAccountType !== ACCOUNT_TYPE_INDIVIDUAL"></v-text-field>
                            <v-btn :style="primaryButtonStyle" @click="createAccount" class="mt-4">Continue</v-btn>
                        </v-form>
                        </v-card-text>
                    </v-card>
                </template>
                -->
            </template>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toMillis } from '@libertyio/time-util-js';
import { isValidEmail } from '@/sdk/input';
import {
    IA_CUSTOMER_CONNECT,
    INTENT_CART,
    INTENT_SIGNUP,
    ACCOUNT_TYPE_INDIVIDUAL,
    ACCOUNT_TYPE_TEAM,
    ACCOUNT_TYPE_ENTERPRISE,
    REGISTRATION_MODE_CLOSED,
} from '@/sdk/constants';
// import LoginCard from '@/components/LoginCard.vue';
// import ProductDisplay from '@/components/ProductDisplay.vue';
// import CheckoutProfileAccountBar from '@/components/CheckoutProfileAccountBar.vue';
import CheckoutSelectAccount from '@/components/CheckoutSelectAccount.vue';

export default {
    components: {
        // LoginCard,
        // ProductDisplay,
        // CheckoutProfileAccountBar,
        CheckoutSelectAccount,
    },
    data: () => ({
        steps: ['start', 'verify_email', 'display_name', 'create_account'],
        step: 'start',
        isViewReady: false,
        interactionId: null,
        errorInvalidInteraction: false,
        errorProductNotFound: false,
        errorProductNotPriced: false,
        product: null,
        price: null,
        email: null, // user's email address
        displayName: null, // user's preferred name
        isEmailVerified: false,
        emailInputError: null,
        emailInputErrorTimer: null,
        nameInputError: null,
        nameInputErrorTimer: null,
        serverError: null,
        serverErrorTimer: null,
        requestError: null,
        requestErrorTimer: null,
        forbiddenError: null,
        forbiddenErrorTimer: null,
        // emailRules: [
        //     (v) => !v || isValidEmail(v) || 'Enter your email address',
        // ],
        submitTimestamp: null,
        redirect: null,
        // create account steps
        newAccountName: null,
        newAccountType: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            user: (state) => state.user,
            account: (state) => state.account,
            accountList: (state) => state.accountList,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            mainWebsiteURL: 'mainWebsiteURL',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isError() {
            return this.errorInvalidInteraction || this.errorProductNotFound || this.errorProductNotPriced;
        },
        isEmailInputVisible() {
            return !this.isError && this.product && (!this.isAuthenticated || !this.user);
        },
        progress() {
            if (this.steps.length === 0) {
                return 0;
            }
            const idx = this.steps.findIndex((value) => value === this.step);
            return Math.ceil(((idx + 1) * 100) / this.steps.length);
        },
        ACCOUNT_TYPE_INDIVIDUAL() {
            return ACCOUNT_TYPE_INDIVIDUAL;
        },
        ACCOUNT_TYPE_TEAM() {
            return ACCOUNT_TYPE_TEAM;
        },
        ACCOUNT_TYPE_ENTERPRISE() {
            return ACCOUNT_TYPE_ENTERPRISE;
        },
        newAccountNameLabel() {
            if (this.newAccountType === ACCOUNT_TYPE_TEAM) {
                return 'Team, project, or business name';
            }
            if (this.newAccountType === ACCOUNT_TYPE_ENTERPRISE) {
                return 'Organization name';
            }
            return 'Account name';
        },
    },
    watch: {
        // isAuthenticatedReady(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.init();
        //     }
        // },
        isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                // this.redirectAuthenticatedUser();
                console.log('signup.vue watch isAuthenticated: changed to authenticated');
                this.whenUserIsAuthenticated();
            }
        },
        session(newValue, oldValue) {
            if (newValue.isAuthenticated && !oldValue.isAuthenticated) {
                console.log('signup.vue watch session: changed to authenticated');
            }
        },
        user(newValue, oldValue) {
            if (newValue.email && !oldValue.email) {
                console.log(`signup.vue watch user: email is now ${newValue.email}`);
            }
            if (this.email !== newValue.email) {
                console.log(`signup.vue watch user: changing email in view from ${this.email} to ${newValue.email}`);
                this.email = newValue.email;
            }
            if (newValue.display_name && !oldValue.display_name) {
                console.log(`signup.vue watch user: display_name is now ${newValue.display_name}`);
            }
            if (this.displayName !== newValue.display_name) {
                console.log(`signup.vue watch user: changing displayName in view from ${this.displayName} to ${newValue.display_name}`);
                this.displayName = newValue.display_name;
            }
        },
        account(newValue, oldValue) {
            if (newValue.id !== oldValue.id) {
                console.log(`signup.vue watch account: id is now ${newValue.id}`);
                // TODO: call selectAccount(newValue.id) to make sure the same account is in the cart ??
            }
        },
        accountList(newValue, oldValue) {
            if (newValue?.length !== oldValue?.length) {
                console.log('signup.vue watch account list: changed length');
            }
            if (!Array.isArray(newValue)) {
                console.log('signup.vue watch account list: not an array');
                return;
            }
            if (newValue.length === 0) {
                console.log('signup.vue watch account list: no accounts');
                if (this.isAuthenticated && !this.account) {
                    if (!this.newAccountName) {
                        this.newAccountName = 'My Account';
                    }
                    if (!this.newAccountType) {
                        this.newAccountType = ACCOUNT_TYPE_INDIVIDUAL;
                    }
                    this.createAccount();
                    return;
                }
            }
            if (newValue.length === 1) {
                console.log('signup.vue watch account list: 1 account');
            }
            if (newValue.length > 1) {
                console.log('signup.vue watch account list: multiple account');
            }
        },
        focus() {
            this.prepareView();
        },
        step(newValue) {
            if (newValue === 'create_account') {
                this.initCreateAccount();
            }
        },
        newAccountType(newValue, oldValue) {
            if (newValue === ACCOUNT_TYPE_INDIVIDUAL && oldValue !== ACCOUNT_TYPE_INDIVIDUAL) {
                if (this.user?.name) {
                    this.newAccountName = this.user.name;
                } else if (this.displayName) {
                    this.newAccountName = this.displayName;
                } else {
                    const today = new Date().toLocaleDateString();
                    this.newAccountName = `Account created ${today}`;
                }
            }
            if ([ACCOUNT_TYPE_TEAM, ACCOUNT_TYPE_ENTERPRISE].includes(newValue)) {
                if (oldValue === ACCOUNT_TYPE_INDIVIDUAL) {
                    this.newAccountName = '';
                }
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newAccountNameInput'); }, 1);
                });
            }
        },
        // isEmailInputVisible(newValue) {
        //     if (newValue) {
        //         this.$nextTick(() => {
        //             setTimeout(() => { this.$activateInput('emailInput'); }, 1);
        //         });
        //     }
        // },
        $route(newValue) {
            let isEdited = false;
            const mapQueryToState = [
                { queryParam: 'step', attr: 'step' },
                { queryParam: 'email', attr: 'email' },
                { queryParam: 'displayName', attr: 'displayName' },
            ];
            for (let i = 0; i < mapQueryToState.length; i += 1) {
                const queryParam = mapQueryToState[i].queryParam;
                const attr = mapQueryToState[i].queryParam;
                if (newValue.query[queryParam] && newValue.query[queryParam] !== this[attr]) {
                    console.log(`route watcher: updating ${attr} from query ${queryParam}: ${newValue.query[queryParam]}`);
                    this[attr] = newValue.query[queryParam];
                    isEdited = true;
                }
            }
            if (isEdited) {
                this.prepareView();
            }
        },
    },
    methods: {
        resetErrors() {
            this.emailInputError = null;
            if (this.emailInputErrorTimer) {
                clearTimeout(this.emailInputErrorTimer);
                this.emailInputErrorTimer = null;
            }
            this.redirect = null;
            // this.verificationExpires = null;
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$store.dispatch('loadInteraction', this.interactionId);
                // const response = await this.$client.main().interaction.get(this.interactionId);
                console.log(`Signup.vue: loadInteraction response: ${JSON.stringify(response)}`);
                const { type, next, state } = response;
                console.log(`Signup.vue: loadInteraction: interaction type: ${type} next: ${next} state ${JSON.stringify(state)}`); // type could be 'customer_connect' or something else, but intent should be 'signup'; state could be {"intent":"signup","user_id":"06K4K946QGWW2FSQ3JCG","user_ref":"06K3HR41S46DGF647W6G","user_email":"jonathan@cryptium.com","user_name":"Jonathan","account_id":"06K6TGPHZPA7ZP7JHTH0","account_ref":"06K6TGKTS5P1AJE13TYG","account_name":"Test3","product_ref":"essential","organization_name":"Cryptium Docs","organization_domain":"docs.cryptium.test"}
                if (![IA_CUSTOMER_CONNECT].includes(type)) {
                    console.error(`Signup.vue: invalid interaction type: ${type}`);
                    this.errorInvalidInteraction = true;
                    return;
                }
                if (![INTENT_CART, INTENT_SIGNUP].includes(state.intent)) {
                    console.error(`Signup.vue: invalid intent: ${state.intent}`);
                    this.errorInvalidInteraction = true;
                    return;
                }
                if (state.account_id && state.account_ref && state.account_name) {
                    this.loadAccount({ id: state.account_id });
                } else if (state.account_ref) {
                    this.loadAccount({ ref: state.account_ref });
                } else {
                    this.$store.commit('account', null);
                    this.$nav.set('account_id', null);
                }
            } catch (err) {
                console.error('Signup.vue: failed to activate token', err);
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
        async loadAccountById(id) {
            const response = await this.$client.site(this.brandprofile).user(this.user.id).account.search({ id });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        async loadAccountByRef(ref) {
            const response = await this.$client.site(this.brandprofile).user(this.user.id).account.search({ ref });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        async loadAccount({ id, ref }) {
            try {
                this.$store.commit('loading', { loadAccount: true });

                let account;
                if (id) {
                    account = await this.loadAccountById(id);
                } else if (ref) {
                    account = await this.loadAccountByRef(ref);
                } else {
                    // TODO: OPEN QUESTION: is this an error, or should we show a dialog for creating a new non-guest account? if client application passes account_type we could use that; otherwise maybe 'personal' should be the default
                    return;
                }
                if (!account) {
                    // TODO: OPEN QUESTION: is this an error, or should we show a dialog for creating a new non-guest account? if client application passes account_type we could use that; otherwise maybe 'personal' should be the default
                    return;
                }

                this.$store.commit('account', account);
                this.$nav.set('account_id', account.id);
            } catch (err) {
                console.error('loadAccount failed', err);
                // this.errorProductNotFound = true;
                this.$store.commit('account', null);
                this.$nav.set('account_id', null);
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadProductById(id) {
            const response = await this.$client.site(this.brandprofile).product.search({ id });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        async loadProductByRef(ref) {
            const response = await this.$client.site(this.brandprofile).product.search({ ref });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        async loadProductByAlias(alias) {
            const response = await this.$client.site(this.brandprofile).product.search({ alias });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        /**
         * Attempt to load the specified product and price. If found, `this.product` and
         * `this.price` may be set, otherwise they will be null.
         */
        async loadProduct() {
            try {
                this.$store.commit('loading', { loadProduct: true });

                let product = null;
                if (this.$route.query.product_id) {
                    product = await this.loadProductById(this.$route.query.product_id);
                }
                if (!product && this.$route.query.product_ref) {
                    product = await this.loadProductByRef(this.$route.query.product_ref);
                }
                if (!product && this.$route.query.product_alias) {
                    product = await this.loadProductByAlias(this.$route.query.product_alias);
                }

                if (!product) {
                    // instead of setting this.errorProductNotFound in the exception handler
                    // from trying to get the price_list from a null product below, just redirect
                    // the user to the catalog to find the product
                    // UPDATE: not redirecting user, because this is a sign up page... they can still finish signing up AND THEN find a product to buy with their new account selected.
                    // const query = {}; // for example, { type: 'service' } if we know it's a service from our query parameters here
                    // this.$router.replace({ name: 'brand-catalog-search', params: { brandprofile: this.$route.params.brandprofile }, query });
                    return;
                }

                this.product = product;

                // all published products must have a price list, but check it just in case
                if (!Array.isArray(this.product.price_list) || this.product.price_list.length === 0) {
                    this.errorProductNotPriced = true;
                    return;
                }

                // if the user arrived with a specific price_ref or price_alias, look for it in the price list

                let price = null;
                if (this.$route.query.product_price_id) {
                    price = this.product.price_list.find((item) => item.id === this.$route.query.product_price_id);
                }
                if (!price && this.$route.query.product_price_ref) {
                    price = this.product.price_list.find((item) => item.ref === this.$route.query.product_price_ref);
                }
                if (!price && this.$route.query.product_price_alias) {
                    price = this.product.price_list.find((item) => item.alias === this.$route.query.product_price_alias);
                }
                if (!price && this.product.price_list.length === 1) {
                    // if the product has only one price, we automatically select it
                    price = this.product.price_list[0];
                }

                this.price = price;
            } catch (err) {
                console.error('loadProduct failed', err);
                this.errorProductNotFound = true;
            } finally {
                this.$store.commit('loading', { loadProduct: false });
            }
        },
        login() {
            const { fullPath } = this.$router.currentRoute;
            if (this.brandselector === 'brandprofile') {
                this.$nav.push({ name: 'brand-login', query: { next: fullPath } });
            } else {
                this.$nav.push({ name: 'login', query: { next: fullPath } });
            }
        },
        async selectAccount(accountId) {
            console.log(`signup.vue: selectAccount ${accountId}`);
            // NOTE: the accountList is really linkAccountUserList, so each item in it is not exactly the same as an account item; for this reason we load the account here and not just assign the selected item
            // TODO: load account first to make sure user has access to it it? or is it already loaded and permission confirmed before we get here?
            this.$nav.set('account_id', accountId);
            await this.$client.site(this.brandprofile).cart.edit({ op: 'set_account', account_id: accountId });
            // update query parameters with the account id
            if (this.$route.query.accountId !== accountId) {
                this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, accountId, t: Date.now() } });
            }
            this.continueAfterSignup();
        },
        logout() {
            // make user logged out and stay in this activity for the email prompt
            this.$store.commit('setSession', { isAuthenticated: false });
        },
        async signup() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.resetErrors();
                if (typeof this.email !== 'string' || this.email.trim().length === 0 || !isValidEmail(this.email)) {
                    this.emailInputError = 'Please enter an email address';
                    this.emailInputErrorTimeout = setTimeout(() => { this.emailInputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signup: true });
                const request = {
                    email: this.email,
                    name: this.displayName,
                    interaction_id: this.interactionId, // null on first request, possibly a value after email verification (but not necessarily)
                    product_id: this.product?.id, // may have a value if user arrived here by selecting a something on the pricing page
                    product_price_id: this.price?.id, // may have a value if user arrived here by selecting a something on the pricing page
                    // product_group_id: this.productGroup?.id, // may have a value if user arrived here by selecting a product group instead of a specific product
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.site(this.brandprofile).authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'redirect':
                        if (response.redirect) {
                            // show a link after 2 seconds in case auto-redirect fails
                            setTimeout(() => {
                                this.redirect = response.redirect;
                            }, 2000);
                            // use replace so that when user taps 'back' button from there, they won't
                            // end up being redirected again to where they just wanted to come back from
                            // TODO: restore this redirect after signup bugs are fixed
                            if (typeof window.location.replace === 'function') {
                                window.location.replace(response.redirect);
                            } else {
                                window.location.href = response.redirect;
                            }
                            return;
                        }
                        console.error('signup error: server redirect response missing redirect url');
                        this.serverError = true;
                        this.serverErrorTimer = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    case 'display_name':
                        // prompt user to enter display name
                        this.updateStep('display_name', {
                            email: this.email,
                        });
                        this.$nextTick(() => {
                            setTimeout(() => { this.$activateInput('displayNameInput'); }, 1);
                        });
                        break;
                    case 'setup_loginshield':
                        // user record was created but LoginShield not set up yet
                        this.updateStep('setup_loginshield');
                        break;
                    case 'authenticated':
                        await this.$store.dispatch('refresh');
                        this.whenUserIsAuthenticated();
                        break;
                    case 'setup_required':
                        // user record was created, but authentication not set up yet
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        await this.$store.dispatch('refresh');
                        this.setup();
                        break;
                    case 'login_required':
                        /*
                        // email is verified but user not authenticated here; redirect to login
                        this.$router.push({ name: 'login' });
                        */
                        this.registered = true;
                        this.updateStep('verify_email');
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimer = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimer = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimer = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimer = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimer = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimer = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimer = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimer = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { signup: false });
                this.isViewReady = true;
            }
        },
        async signupWithEmail() {
            console.log('signupWithEmail');
            // store the email address in query parameters before calling signup, so if user taps back button later or reloads the page we'll still have it
            this.$router.replace({ name: 'brand-transaction-signup', params: { ...this.$route.params }, query: { ...this.$route.query, email: this.email, t: Date.now() } });
            return this.signup();
        },
        async signupWithDisplayName() {
            console.log('signupWithDisplayName');
            // store the display name in query parameters before calling signup, so if user taps back button later or reloads the page we'll still have it
            this.$router.replace({ name: 'brand-transaction-signup', params: { ...this.$route.params }, query: { ...this.$route.query, display_name: this.displayName, t: Date.now() } });
            return this.signup();
        },
        async switchAccount() {
            this.resetErrors();
            this.updateStep('start');
            await this.$store.dispatch('refresh');
            if (!this.isAuthenticated) {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('emailInput'); }, 1); // TODO: we also need to update something to indicate the user doens't have a current account selected ...
                });
            }
        },
        async whenUserIsAuthenticated() {
            console.log('signup.vue: whenUserIsAuthenticated');
            await this.$store.dispatch('loadAccountList');
            console.log(`signup.vue: whenUserIsAuthenticated account list length ${this.accountList.length}`);
            if (this.accountList.length === 0) {
                console.log('signup.vue: user is authenticated, switching to step create_account');
                this.updateStep('create_account');
                /*
                this.$router.replace({
                    name: 'brand-transaction-signup',
                    params: { ...this.$route.params },
                    query: {
                        ...this.$route.query,
                        email: this.email,
                        step: 'create_account',
                        t: Date.now(),
                    },
                });
                this.isViewReady = true;
                return;
                */
                console.log('signup.vue: user is authenticate, setting new account name and type');
                // instead of prompting user for account type, just create an individual account; user can change it later if needed
                this.newAccountName = this.user.name;
                this.newAccountType = ACCOUNT_TYPE_INDIVIDUAL;
                await this.createAccount();
                this.isViewReady = true;
                return;
            }
            console.log('signup.vue: user already has account');
            if (!this.account) {
                console.log('signup.vue: select an account');
                this.updateStep('start'); // for authenticated users, the 'start' step prompts to select an account
                this.isViewReady = true;
                return;
            }
            // if (this.accountList.length === 1) {
            //     // if user has exactly one account, auto-select it
            //     // TODO: check that is' not arleady selectedd.... in case user just left page and came back, if everything is the same we don't need to select the same account again
            //     await this.selectAccount(this.accountList[0].account_id);
            //     console.log('signup.vue: init after auto-select account');
            // }
            console.log('signup.vue: continueAfterSignup...');
            this.continueAfterSignup();
        },
        async init() {
            console.log('signup.vue: init');
            // if (this.isAuthenticated) {
            //     this.redirectAuthenticatedUser();
            // }
            if (this.$route.query.i) {
                this.interactionId = this.$route.query.i;
                this.loadInteraction();
            }
            console.log('signup.vue: init after load interaction');

            // The signup view is for subscription products. We need to look up the product and
            // check if it's a subscription product.
            this.loadProduct();
            console.log('signup.vue: init after load product');

            await this.$store.dispatch('refresh');

            // Refresh the account list, in case user wants to switch accounts, all current accounts will be available
            if (this.isAuthenticated) {
                this.whenUserIsAuthenticated();
                return;
            }
            console.log('signup.vue: init after isAuthenticated check');

            const emailFromQuery = this.$route.query.email ?? '';
            const displayNameFromQuery = this.$route.query.display_name ?? '';

            // if user arrives with `email` and `step=verify_email`, call signup to check
            // the email address, if it's not verified we will redirect to EtherLink, and
            // eventually return here with a verified address or cancel the interaction
            if (this.$route.query.step === 'verify_email' || !this.email) {
                if (emailFromQuery) {
                    this.email = emailFromQuery;
                    this.updateStep('verify_email');
                    await this.signup();
                } else {
                    this.updateStep('start');
                    this.$nextTick(() => {
                        setTimeout(() => { this.$activateInput('emailInput'); }, 1);
                    });
                }
                this.isViewReady = true;
                return;
            }
            console.log('signup.vue: init after verify email');

            // if user arrives with `email` and `step=display_name`, show the email address and
            // ask user for their display name (if we didn't already)
            if (this.$route.query.step === 'display_name' || !this.displayName) {
                this.updateStep('display_name');
                if (!this.email && emailFromQuery) {
                    this.email = emailFromQuery;
                }
                if (displayNameFromQuery) {
                    this.displayName = displayNameFromQuery;
                    await this.signup();
                }
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('displayNameInput'); }, 1);
                });
                this.isViewReady = true;
                return;
            }

            console.log('signup.vue: init after display name');

            if (this.$route.query.step === 'create_account') {
                this.updateStep('create_account');
                if (emailFromQuery) {
                    this.email = emailFromQuery;
                }
                // instead of prompting user for account type, just create an individual account; user can change it later if needed
                await this.createAccount();
                this.isViewReady = true;
                return;
            }
            console.log('signup.vue: init after create account');

            if (!this.$route.query.step) {
                this.updateStep('start');
            } else {
                this.step = 'start';
            }
            this.email = emailFromQuery;
            this.isViewReady = true;
            this.$nextTick(() => {
                setTimeout(() => { this.$activateInput('emailInput'); }, 1);
            });

            // // the first time user arrives on this page, there would not be an interaction id in the query,
            // // but there could be an email address to pre-fill;
            // // the second time the user arrives on this page (after email verification is completed), there
            // // would be an interaction id also;
            // // we only auto-submit if there's an interaction id meaning the user got here via one of our own links
            // this.interactionId = this.$route.query.i;
            // if (!this.email) {
            //     this.email = this.$route.query.email ?? '';
            // }
            // if (this.interactionId && this.email) {
            //     this.signup();
            //     return;
            // }

            // this.isViewReady = true;
            // this.$nextTick(() => {
            //     setTimeout(() => { this.$activateInput('emailInput'); }, 1);
            // });
        },
        async initCreateAccount() {
            if (!this.newAccountType) {
                // TODO: check organization settings, is there a default?
                this.newAccountType = ACCOUNT_TYPE_INDIVIDUAL;
            }
        },
        async createAccount() {
            try {
                if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitTimestamp = Date.now();
                this.$store.commit('loading', { createAccount: true });
                console.log('createAccount');
                const result = await this.$client.site(this.brandprofile).user(this.user.id).account.create({ name: this.newAccountName, type: this.newAccountType });
                console.log(`createAccount result: ${JSON.stringify(result)}`);
                if (result.isCreated && result.id && result.linkAccountUser) {
                    console.log(`createAccount successful: ${result.id}`);
                    await this.$store.dispatch('loadAccountList');
                    await this.selectAccount(result.id);
                } else if (!result.isCreated && result.redirect) {
                    console.log(`signup.vue: createAccount: account not created; redirect: ${result.redirect}`);
                    // TODO: this should never happen if we check organization's registration mode BEFORE we try to create an account... so if it's set to 'connect' we don't even try account.create() above, we just redirect the user to the organization's registration url
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(result.redirect);
                    } else {
                        window.location.href = result.redirect;
                    }
                } else if (!result.isCreated && result.registration_mode === REGISTRATION_MODE_CLOSED) {
                    console.log('REGISTRATION CLOSED');
                    this.$router.push({ name: 'brand-transaction-signup-registration-closed', params: { ...this.$route.params }, query: { ...this.$route.query, t: Date.now() } });
                } else if (!result.isCreated) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create a new account' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create a new account' });
                }
            } catch (err) {
                console.error('create account failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create a new account' });
                // TODO: switch back to select-account mode in the dialog
            } finally {
                this.$store.commit('loading', { createAccount: false });
            }
        },
        async continueAfterSignup() {
            console.log('continueAfterSignup: continue after signup');
            const query = {};
            ['product_id', 'product_alias', 'product_ref', 'product_price_id', 'product_price_alias', 'product_price_ref', 'product_group_id', 'product_group_ref', 'product_group_alias'].forEach((queryparam) => {
                query[queryparam] = this.$route.query[queryparam];
            });
            if (this.product) {
                // TODO: redirect to authenticated user subscribe to product, which should be within the account pages so we use the selected account id
                console.log(`continueAfterSignup account id from query: ${JSON.stringify(this.$route.query.accountId)} vuex ${JSON.stringify(this.account?.id)} nav ${JSON.stringify(this.$nav.get('account_id'))}`);
                const accountId = this.$route.query.accountId ?? this.account?.id ?? this.$nav.get('account_id');
                console.log(`continueAfterSignup: product ${this.product.id}, redirecting to subscribe with account ${JSON.stringify(accountId)}`);
                if (accountId) {
                    this.$router.replace({ name: 'account-subscription-create', params: { brandprofile: this.$route.params.brandprofile, accountId }, query });
                } else {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Please select an account first' });
                }
            } else {
                // didn't find the specified product, or no product was specified, so redirect to catalog
                console.log(`continueAfterSignup: product not found, redirecting to catalog with query ${JSON.stringify(query)}`);
                // TODO: only redirect back to catalog if we don't know what product the user wants ... if we have a valid product id, then do a checkout page dedicated to signup.
                if (Object.keys(query).length > 0) {
                    console.log('continueAfterSigup: product and/or price selected, redirect to catalog item');
                    // product and/or price selected, so redirect to catalog item
                    this.$router.replace({ name: 'brand-catalog-item', params: { brandprofile: this.$route.params.brandprofile }, query });
                } else {
                    console.log('continueAfterSigup: no product and/or price selected, redirect to search services');
                    // redirect to catalog main page with filter for services
                    // TODO: OPEN QUESTION: alternatively, redirect to dashboard and let user follow recommendations from there?
                    this.$router.replace({ name: 'brand-catalog-search', params: { brandprofile: this.$route.params.brandprofile }, query: { type: 'service' } });
                }
            }
        },
        /**
         * To set a new step, call this function with the step name (e.g. 'start', 'create_account', etc)
         * and optional additional query parameters to set in the query at the same time.
         */
        updateStep(step, query = {}) {
            console.log(`updateStep: ${step}, query: ${JSON.stringify(query)}`);
            this.step = step;
            this.$router.replace({ name: 'brand-transaction-signup', params: { ...this.$route.params }, query: { ...this.$route.query, ...query, step, t: Date.now() } });
        },
        prepareView() {
            const step = this.step;
            const isAuthenticated = this.isAuthenticated;
            console.log(`prepareView: ${JSON.stringify({ step, isAuthenticated })}`);
            if (step === 'display_name') {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('displayNameInput'); }, 1);
                });
                return;
            }
            if (step === 'start' && !isAuthenticated) {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('emailInput'); }, 1);
                });
            }
        },
    },
    mounted() {
        // TODO: this should be done in accordance with organization settings... some organizations might require users to set a password/authentication before they make any purchase, others are happy to let users skip this step, complete their purchase, and set a password later from account settings  (we'd show an alert in their dashboard advising them to protect their account, with a link to that page);  usually if someone has a password and they forgot it, password reset is done via email anyway so signing in with only email verification isn't a significant decrease in security in that case (but it is less security than LoginShield authenticator)
        this.init();
    },
};
</script>
